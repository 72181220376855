.content {
  padding: $content-padding;
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 0 $headings-margin-bottom 0;
    
    font-family: $headings-font-family;
    font-style: $headings-font-style;
    font-weight: $headings-font-weight;
    line-height: $headings-line-height;
    color: $headings-color;
  }

  h1 {
    margin: $content-h1-margin;

    font-family: $content-h1-font_family;
    font-size: $content-h1-font_size;
    font-style: $content-h1-font_style;
    font-weight: $content-h1-font_weight;
    line-height: $content-h1-line_height;
    color: $content-h1-color;

    @include media-breakpoint-up(sm) {
      font-size: $content-h1-font_size_sm;
      line-height: $content-h1-line_height_sm;
    }
  }

  h2 {
    margin: $content-h2-margin;

    font-family: $content-h2-font_family;
    font-size: $content-h2-font_size;
    font-style: $content-h2-font_style;
    font-weight: $content-h2-font_weight;
    line-height: $content-h2-line_height;
    color: $content-h2-color;

    @include media-breakpoint-up(sm) {
      font-size: $content-h2-font_size_sm;
      line-height: $content-h2-line_height_sm;
    }
  }

  h3 {
    margin: $content-h3-margin;

    font-family: $content-h3-font_family;
    font-size: $content-h3-font_size;
    font-style: $content-h3-font_style;
    font-weight: $content-h3-font_weight;
    line-height: $content-h3-line_height;
    color: $content-h3-color;

    @include media-breakpoint-up(sm) {
      font-size: $content-h3-font_size_sm;
      line-height: $content-h3-line_height_sm;
    }
  }

  h4 {
    margin: $content-h4-margin;

    font-family: $content-h4-font_family;
    font-size: $content-h4-font_size;
    font-style: $content-h4-font_style;
    font-weight: $content-h4-font_weight;
    line-height: $content-h4-line_height;
    color: $content-h4-color;

    @include media-breakpoint-up(sm) {
      font-size: $content-h4-font_size_sm;
      line-height: $content-h4-line_height_sm;
    }
  }

  h5 {
    margin: $content-h5-margin;

    font-family: $content-h5-font_family;
    font-size: $content-h5-font_size;
    font-style: $content-h5-font_style;
    font-weight: $content-h5-font_weight;
    line-height: $content-h5-line_height;
    color: $content-h5-color;

    @include media-breakpoint-up(sm) {
      font-size: $content-h5-font_size_sm;
      line-height: $content-h5-line_height_sm;
    }
  }

  h6 {
    margin: $content-h6-margin;

    font-family: $content-h6-font_family;
    font-size: $content-h6-font_size;
    font-style: $content-h6-font_style;
    font-weight: $content-h6-font_weight;
    line-height: $content-h6-line_height;
    color: $content-h6-color;

    @include media-breakpoint-up(sm) {
      font-size: $content-h6-font_size_sm;
      line-height: $content-h6-line_height_sm;
    }
  }

  p {
    margin: $content-p-margin;

    font-size: $content-p-font_size;
    font-style: $content-p-font_style;
    font-weight: $content-p-font_weight;
    line-height: $content-p-line_height;
    color: $content-p-color;
  }

  ul:not(.vocabulary_term_list),
  ol:not(.carousel-indicators) {
    li {
      margin: $content-list-item-margin;

      font-size: $content-list-item-font_size;
      font-style: $content-list-item-font_style;
      font-weight: $content-list-item-font_weight;
      line-height: $content-list-item-line_height;
      color: $content-list-item-color;
    }
  }

  ul.custom-list {
    @extend .list-unstyled;

    li {
      @extend .position-relative;
      padding: $content-custom-list-item-padding;

      &::before {
        @extend .position-absolute;
        left: 0;

        @extend .d-block;
        height: $content-custom-list-item-icon-height;

        font-family: 'Font Awesome 5 Pro';
        font-size: $content-custom-list-item-icon-font_size;
        font-weight: $content-custom-list-item-icon-font_weight;
        line-height: $content-custom-list-item-icon-line_height;
        color: $content-custom-list-item-icon-color;

        content: $content-ol-custom-list-item-icon;
      }
    }
  }

  ol.custom-list {
    @extend .list-unstyled;

    li {
      @extend .position-relative;
      padding: $content-ol-custom-list-item-padding;

      &::before {
        @extend .position-absolute;
        left: 0;

        @extend .d-block;
        height: $content-ol-custom-list-item-icon-height;

        font-family: 'Font Awesome 5 Pro';
        font-size: $content-ol-custom-list-item-icon-font_size;
        font-weight: $content-ol-custom-list-item-icon-font_weight;
        line-height: $content-ol-custom-list-item-icon-line_height;
        color: $content-ol-custom-list-item-icon-color;

        content: $content-ol-custom-list-item-icon;
      }
    }
  }
}

/* content > bg-dark
   ========================================================================== */
.content.bg-dark {
  h1 { color: $content-h1-color--dark; }
  h2 { color: $content-h2-color--dark; }
  h3 { color: $content-h3-color--dark; }
  h4 { color: $content-h4-color--dark; }
  h5 { color: $content-h5-color--dark; }
  h6 { color: $content-h6-color--dark; }

  p { color: $content-p-color--dark; }

  ul,
  ol {
    li {
      color: $content-list-item-color--dark;
    }
  }

  ul.custom-list {
    li {
      &::before {
        color: $content-custom-list-item-icon-color--dark;
      }
    }
  }

  ol.custom-list {
    li {
      &::before {
        color: $content-ol-custom-list-item-icon-color--dark;
      }
    }
  }
}

/* content-padding
   ========================================================================== */
.content-padding {
  padding: $content-padding;
}