$body-padding: 0;
$body-sticky-padding: 0;

/* Custom theme-colors
   ========================================================================== */

   $base-1 : #45425A;
   $base-2: #E02F3C;
   $base-3: #2268A2;
   $base-4: #CBCBCB;
   $base-5: #707070;
   $highlight: #C6D314;
   $base-6: #EFA18A;

/* ==========================================================================
   owl-carousel
   ========================================================================== */
   $carousel-min_height: 50vh !default;
   $carousel-min_height_lg: 95vh !default;
