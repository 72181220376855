&.accommodation_overview {
    .main {
        .intro {
            background: $base-6;
            padding: 80px 0 140px;
            margin-top: 16px;
            position: relative;

            @include media-breakpoint-up(sm) {
                padding: 100px 0 200px;
            }

            .container-default {
                .container-holder {
                    p {
                        max-width: 698px;
                    }
                }
            }
        }
        .content {
            padding: 0 0 80px;
            margin-top: -100px;

            @include media-breakpoint-up(sm) {
                margin-top: -170px;
            }

            .container-default {
                .container-holder {
                    .grid.page_block.accommodation_overview {
                        .item {
                            @include media-breakpoint-up(lg) {
                                @include make-col(4);
                            }

                            .card {
                                &-body {
                                    .card-caption {
                                        position: relative;
                                        padding-top: 12px;

                                        .card-description {
                                            overflow: unset;
                                            position: static !important; // !important needed to override base css

                                            .list.list-icons {
                                                position: absolute;
                                                top: -24px;
                                                width: 100%;
                                                display: flex;
                                                flex-wrap: nowrap;

                                                .list-item {
                                                    font-size: $font-size-base_sm;

                                                    &:not(:last-child) {
                                                        margin-right: 20px;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

&.accommodation_search_book {
    .main {
        .intro {
            background: $base-6;
            padding: 80px 0;
            margin-top: 16px;
            position: relative;

            @include media-breakpoint-up(sm) {
                padding: 100px 0;
            }

            .container-default {
                .container-holder {
                    p {
                        max-width: 698px;
                    }
                }
            }
        }
    }
}

&.accommodation_detail {
    .main {
        .content.intro {
            padding: 80px 0 10px !important;
            transform: none !important;
            margin-top: 15px;

            @include media-breakpoint-up(sm) {
                padding: 100px 0 !important;
            }
            .container-default {
                .container-holder {
                    .accommodation_detail {
                        .info {
                            margin-bottom: 40px !important; // !important needed to override base css

                            @include media-breakpoint-up(lg) {
                                margin-bottom: 0 !important; // !important needed to override base css
                            }

                            .btn-back {
                                font-size: $font-size-base_sm;
                                font-family: $font-red-hat-display;
                                position: absolute;
                                transform: translateY(-50px);
                                padding-left: 20px;

                                &:hover {
                                    text-decoration: none;

                                    &::before {
                                        left: -4px;
                                    }
                                }
                                &::before {
                                    position: absolute;
                                    top: 40%;
                                    left: -2px;
                                    height: 18px;
                                    width: 18px;
                                    content: "\F0A8" !important; // !important needed to override base css
                                    transform: translateY(-50%);
                                    font-size: $font-size-base * 1.0625 !important; // !important needed to override base css
                                    font-family: $font-awesome;
                                    font-weight: normal;
                                }
                            }
                            .accommodation-properties {
                                &__item {
                                    padding-left: 0;
                                    &::before {
                                        content: none !important;
                                    }
                                }
                                &__icon,
                                &__label {
                                    color: $primary;
                                    font-weight: 600;

                                    i {
                                        font-weight: 600;
                                    }
                                }
                            }
                            p {
                                margin-bottom: 40px;
                            }
                            ul {
                                @extend .list-default;

                                li {
                                    font-family: unset; 
                                    margin-bottom: 15px;
                                    padding-left: 22px;
                                    letter-spacing: 1.4px;
                                    position: relative;
                                    font-size: .875rem!important;  

                                    &::before {
                                        position: absolute;
                                        left: 0;
                                        top: 40%;
                                        content: "\F058";
                                        transform: translateY(-55%);
                                        font-size: $font-size-base_xxs !important;
                                        font-family: $font-awesome;
                                        font-weight: 400 !important;
                                        color: $base-2;
                                        width: 12px;
                                        height: 12px;
                                    }
                                }
                            }
                        }
                        .info-extra {
                            margin-bottom: 80px !important;

                            @include media-breakpoint-up(sm) {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
        .content {
            padding: 0;
            transform: translateY(-100px);

            .container-default {
                .container-holder {
                    .accommodation_object_overview {
                        .item {
                            @include media-breakpoint-up(lg) {
                                @include make-col(4);
                            }
                            .card {
                                &-image {
                                    background: $white;

                                    .card-image-link {
                                        position: relative;

                                        &::after {
                                            content: url("/images/banner-bottom-curve.svg");
                                            display: block;
                                            position: absolute;
                                            bottom: -8px;
                                            width: 100%;
                                            height: 30px;
                                            z-index: 1;
                                            text-align: center;
                                        }
                                    }
                                    .accommodation-object-gallery-link {
                                        display: flex;
                                        justify-content: center;
                                        z-index: 11;
                                        position: relative;
                                        font-size: $font-size-base_sm;
                                        font-weight: 600;
                                        text-decoration: none;
                                        position: relative;

                                        &::before {
                                            content: "\F002";
                                            font-family: $font-awesome;
                                            color: $base-2;
                                            font-size: $font-size-base;
                                            position: absolute;
                                            left: auto;
                                            bottom: 16px;
                                            font-weight: 700;
                                        }
                                    }
                                }
                                &-body {
                                    justify-content: center;
                                    align-items: center;

                                    .card-title {
                                        text-align: center;
                                    }
                                    .card-buttons {
                                        .card-btn {
                                            font-weight: 700 !important;
                                            @extend .btn-primary;
                                            padding: 5px 25px !important;

                                            &::after {
                                                content: none;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.accommodation_object_overview {
    .card {
        .card-image {
            .accommodation-object-gallery-link-hidden {
                display: none;
            }
        }
    }
}

&.accommodation_category_overview {
    .content {
        .container-default {
            .container-holder {
                .grid.accommodation_category_overview {
                    .item {
                        @include media-breakpoint-up(lg) {
                            @include make-col(4);
                        }
                    }
                }
            }
        }
    }
}

&.accommodation_category_detail {
    .content {
        .container-default {
            .container-holder {
                .grid.accommodation_overview {
                   .item {
                    @include media-breakpoint-up(lg) {
                        @include make-col(4);
                    }
                   }
                }
            }
        }
    }
}