/* bootstrap > theme-colors
   ========================================================================== */
$primary: #eb4615 !default;
$secondary: #272727 !default;

/* bootstrap > font
      ========================================================================== */
@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Text&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@700;900&display=swap");

$font-red-hat-text: "Red Hat Text", sans-serif;
$font-red-hat-display: "Red Hat Display", sans-serif;
$font-awesome: "Font Awesome 5 Pro";

$font-family-base: $font-red-hat-text !default;

$font-size-base: 1rem !default; //16px
$font-size-lg: 1.125rem !default; //18px
$font-size-base_sm: 0.875rem !default; //14px
$font-size-base_xs: 0.813rem !default; //13px
$font-size-base_xxs: 0.75rem !default; //12px

$h1-font-size: $font-size-base * 2.25 !default;
$h2-font-size: $font-size-base * 1.5 !default;
$h3-font-size: $font-size-base * 1.25 !default;
$h5-font-size: $font-size-base * 0.875 !default;

/* bootstrap > typography
   ========================================================================== */
$headings-font-family: $font-red-hat-display !default;

body {
   &.default {
      margin: 20px 20px 0;
      overflow-x: hidden;
   }
}
