&.home {
	.intro { padding: 8vh 0 4vh 0; }

	.home-blocks { padding: 4vh 0 8vh 0; }

	.spacer {
		min-height: $carousel-min_height;
		@extend .loading-overlay;
	
		.container-default {
			@extend .px-0;
		}
	
		&.large {
			min-height: $carousel-min_height_lg;
		}
	}

	.home-double { padding: 8vh 0; }

	.slider-section { padding: 8vh 0; }

	.inspiration-blocks { padding: 8vh 0; }
}