&.landing_page {
    .main {
        .intro {
            background: $base-6;
            padding: 80px 0 40px;
            margin-top: 16px;
            position: relative;

            @include media-breakpoint-up(sm) {
                padding: 100px 0 80px;
            }
            &::after {
                background: $white;
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                display: block;
                height: 200px;

                @media screen and (max-width: 415px) {
                    // Specific for iphone 5 and smallre devices
                    height: 135px;
                }
            }
            .container-two-columns {
                .container-holder {
                    .column.one {
                        .page_block.link {
                            @extend .btn-secondary;
                            margin-top: 20px;
                            margin-bottom: 20px;

                            @include media-breakpoint-up(sm) {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
        .content-text {
            @include media-breakpoint-up(sm) {
                margin-top: -40px;
            }
        }
        .highlight {
            padding: 40px 0;

            @include media-breakpoint-up(sm) {
                padding: 60px 0;
            }
            .container-default {
                .container-holder {
                    p {
                        max-width: 774px;
                        margin-top: 14px;
                    }
                    .page_block.link {
                        @extend .btn-primary;
                    }
                }
            }
        }
        .slider.owl-drag {
            padding: 60px 0 0;

            @include media-breakpoint-up(sm) {
                padding: 80px 0 0;
            }
        }
    }
}
