.eyecatcher,
.slider.bg-dark.owl-drag {
    .container-holder {
        position: relative;

        .owl-carousel {
            .owl-stage-outer {
                .owl-stage {
                    .owl-item {
                        .item {
                            .owl-container {
                                .owl-caption {
                                    position: absolute;
                                    left: 0;
                                    right: 0;
                                    bottom: 90px;
                                    text-align: center;

                                    @include media-breakpoint-up(sm) {
                                        bottom: 150px;
                                    }
                                    .owl-title {
                                        font-family: $font-red-hat-display;
                                        font-size: $h1-font-size;

                                        @include media-breakpoint-up(sm) {
                                            font-size: $font-size-base * 3.75;
                                            text-align: center;
                                            text-shadow: none;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.slider.bg-dark,
.slider.owl-drag {
    .owl-nav,
    .owl-dots {
        display: none;
    }
    min-height: $carousel-min_height;

    @include media-breakpoint-up(lg) {
        min-height: 725px;
    }
    .container-holder {
        .owl-carousel {
            .owl-stage-outer {
                .owl-stage {
                    .owl-item {
                        .item {
                            min-height: $carousel-min_height;

                            @include media-breakpoint-up(lg) {
                                min-height: 725px;
                            }
                            .owl-container {
                                .owl-caption {
                                    position: absolute;
                                    left: 0;
                                    right: 0;
                                    bottom: 70px;
                                    text-align: center;

                                    .owl-title {
                                        font-family: $font-red-hat-display;
                                        font-size: $h1-font-size;

                                        @include media-breakpoint-up(sm) {
                                            font-size: $font-size-base * 3.75;
                                            text-align: center;
                                            text-shadow: none;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .owl-dots {
                display: flex !important; // !important needed to override base css

                .owl-dot {
                    &.active {
                        span {
                            background: $primary;
                        }
                    }
                    span {
                        width: 30px;
                        height: 6px;
                        border-radius: 0;
                        background: $white;
                        border: 1px solid $base-5;
                    }
                }
            }
            .owl-nav {
                display: flex !important; // !important needed to override base css

                .owl-prev,
                .owl-next {
                    position: relative;
                    width: 100%;

                    &::before {
                        display: block;
                        position: absolute;
                    }
                    i {
                        position: relative;
                        height: 105px;
                        text-shadow: none;
                        width: 100%;

                        &::before {
                            position: absolute;
                            bottom: 0;
                            font-size: $font-size-base * 1.5 !important; // !important needed to override base css
                            font-family: $font-awesome;
                            font-weight: normal;
                            color: $primary;
                        }
                    }
                }
                .owl-prev {
                    &::before {
                        content: url("/images/right-curve.svg");
                        left: -10px;
                    }
                    i {
                        &::before {
                            left: -10px;
                            content: "\f0a8" !important; // !important needed to override base css
                        }
                    }
                }
                .owl-next {
                    position: relative;
                    width: 100%;

                    &::before {
                        content: url("/images/left-curve.svg");
                        right: -10px;
                    }
                    i {
                        &::before {
                            content: "\f0a9" !important; // !important needed to override base csc
                            right: -10px;
                        }
                    }
                }
            }
        }
    }
}

//Facilities carousel

.facilities {
    .grid.page_block.slider.owl-carousel {
        align-items: center;

        @include media-breakpoint-down(sm) {
            margin: 0;
            min-height: 30vh;
        }

        .item {
            justify-content: center;
        }
        @media only screen and (max-width: 900px) and (orientation: landscape),
            only screen and (max-width: 767px) and (orientation: portrait) {
            // Specific for landscape & portrait device
            .owl-nav {
                display: flex;
                bottom: 70%;

                .owl-prev,
                .owl-next {
                    position: relative;
                    width: 100%;

                    &::before {
                        display: block;
                        position: absolute;
                    }
                    i {
                        position: relative;
                        height: 105px;
                        text-shadow: none;
                        width: 100%;

                        &::before {
                            position: absolute;
                            bottom: 0;
                            font-size: $font-size-base * 1.5 !important; // !important needed to override base css
                            font-family: $font-awesome;
                            font-weight: normal;
                            color: $primary;
                        }
                    }
                }
                .owl-prev {
                    &::before {
                        content: url("/images/right-curve.svg");
                        left: -10px;
                    }
                    i {
                        &::before {
                            left: -10px;
                            content: "\f0a8" !important; // !important needed to override base css
                        }
                    }
                }
                .owl-next {
                    position: relative;
                    width: 100%;

                    &::before {
                        content: url("/images/left-curve.svg");
                        right: -10px;
                    }
                    i {
                        &::before {
                            content: "\f0a9" !important; // !important needed to override base csc
                            right: -10px;
                        }
                    }
                }
            }
        }

        .owl-dots {
            display: none;
        }
    }
}

//News carousel

.news {
    .card-columns.slider.owl-carousel {
        @include media-breakpoint-down(sm) {
            .owl-nav {
                display: flex;
                bottom: 50%;

                .owl-prev,
                .owl-next {
                    position: relative;
                    width: 100%;

                    &::before {
                        display: block;
                        position: absolute;
                    }
                    i {
                        position: relative;
                        height: 105px;
                        text-shadow: none;
                        width: 100%;

                        &::before {
                            position: absolute;
                            bottom: 0;
                            font-size: $font-size-base * 1.5 !important; // !important needed to override base css
                            font-family: $font-awesome;
                            font-weight: normal;
                            color: $primary;
                        }
                    }
                }
                .owl-prev {
                    &::before {
                        content: url("/images/right-curve.svg");
                        left: -10px;
                    }
                    i {
                        &::before {
                            left: -10px;
                            content: "\f0a8" !important; // !important needed to override base css
                        }
                    }
                }
                .owl-next {
                    position: relative;
                    width: 100%;

                    &::before {
                        content: url("/images/left-curve.svg");
                        right: -10px;
                    }
                    i {
                        &::before {
                            content: "\f0a9" !important; // !important needed to override base csc
                            right: -10px;
                        }
                    }
                }
            }
        }

        .owl-dots {
            display: none;
        }
    }
}

//Info extra

.info-extra {
    margin-bottom: 0 !important;

    .owl-carousel.page_block.accommodation_detail,.owl-carousel.page_block.service_detail,.owl-carousel.page_block.service_category_detail {
        margin: 0 !important; // !important needed to override base css page_block 
        padding-bottom: 0 !important; // !important needed to override base css
        min-height: auto;

        .owl-dots {
            display: none;
        }
        .owl-nav {
            display: flex;

            .owl-prev,
            .owl-next {
                &::before {
                    display: block;
                    position: absolute;
                }
                i {
                    position: relative;
                    height: 75px;
                    text-shadow: none;
                    width: 100%;

                    &::before {
                        position: absolute;
                        bottom: 0;
                        font-size: $font-size-base * 1.5 !important; // !important needed to override base css
                        font-family: $font-awesome;
                        font-weight: normal;
                        color: $white;
                    }
                }
            }
            .owl-prev {
                &::before {
                    content: "";
                    left: -20px;
                    background: url("/images/right-curve-transparent.svg") no-repeat center center;
                    width: 48px;
                    height: 120px;
                }
                i {
                    &::before {
                        left: -10px;
                        content: "\f0a8" !important; // !important needed to override base css
                    }
                }
            }
            .owl-next {
                position: relative;
                width: 100%;

                &::before {
                    content: "";
                    right: -20px;
                    background: url("/images/left-curve-transparent.svg") no-repeat center center;
                    width: 48px;
                    height: 120px;
                }
                i {
                    &::before {
                        content: "\f0a9" !important; // !important needed to override base csc
                        right: -10px;
                    }
                }
            }
        }
    }
}
&.landing_page {
    .intro {
        .column.two {
            .owl-carousel.page_block.image_collection {
                .owl-dots {
                    display: none;
                }
                .owl-nav {
                    display: flex;

                    .owl-prev,
                    .owl-next {
                        &::before {
                            display: block;
                            position: absolute;
                        }
                        i {
                            position: relative;
                            height: 75px;
                            text-shadow: none;
                            width: 100%;

                            &::before {
                                position: absolute;
                                bottom: 0;
                                font-size: $font-size-base * 1.5 !important; // !important needed to override base css
                                font-family: $font-awesome;
                                font-weight: normal;
                                color: $white;
                            }
                        }
                    }
                    .owl-prev {
                        &::before {
                            content: "";
                            left: -20px;
                            background: url("/images/right-curve-transparent.svg") no-repeat center center;
                            width: 48px;
                            height: 120px;
                        }
                        i {
                            &::before {
                                left: -10px;
                                content: "\f0a8" !important; // !important needed to override base css
                            }
                        }
                    }
                    .owl-next {
                        position: relative;
                        width: 100%;

                        &::before {
                            content: "";
                            right: -20px;
                            background: url("/images/left-curve-transparent.svg") no-repeat center center;
                            width: 48px;
                            height: 120px;
                        }
                        i {
                            &::before {
                                content: "\f0a9" !important; // !important needed to override base csc
                                right: -10px;
                            }
                        }
                    }
                }
            }
        }
    }
}
.eyecatcher {
    .owl-carousel {
        @media only screen and (max-width: 900px) and (orientation: landscape) {
            // Specific for landscape device
            min-height: 350px !important; // !important needed to override base css
        }
        .owl-stage-outer {
            .owl-stage {
                .owl-item {
                    .item {
                        @media only screen and (max-width: 900px) and (orientation: landscape) {
                            // Specific for landscape device
                            min-height: 350px !important; // !important needed to override base css
                        }
                    }
                }
            }
        }
        .owl-nav,
        .owl-dots {
            display: none;
        }
    }
}
