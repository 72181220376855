&.blog_post_overview {
	.main {
		.content {
			.container-default.container {
				.container-holder {
					.page_block.blog_overview {
						.item {
							@include media-breakpoint-up(lg) {
								@include make-col(4);
							}
						}
					}
				}
			}
		}
	}
}

&.assortiment_overview {
	.main {
		.content {
			.container-default.container {
				.container-holder {
					.page_block.assortiment_overview {
						.item {
							@include media-breakpoint-up(lg) {
								@include make-col(4);
							}
						}
					}
				}
			}
		}
	}
}

.search-and-book-widget {
	.tommy-zeb-wrapper.tommy-zeb-wrapper-mini {
		z-index: 100;
		width: 100%;
	}
}

&.home {
	.search-and-book-widget {
		.tommy-zeb-wrapper.tommy-zeb-wrapper-mini {
			margin-top: -200px;
		}
	}
}

&:not(.home) {
	.search-and-book-widget {
		@include media-breakpoint-down(lg) {
			display: none;
		}

		.tommy-zeb-wrapper.tommy-zeb-wrapper-mini {
			margin-top: -40px;

			@include media-breakpoint-up(xl) {
				height: 0;
			}
		}
	}
}
