// common element styles
p {
	font-size: $font-size-base_sm !important; // !important needed to override base css
	letter-spacing: 1.4px;
}

img {
	max-width: 100%;
}

h1 {
	@include media-breakpoint-down(sm) {
		font-size: $font-size-base * 1.5 !important; // !important needed to override base css
	}
}

h2 {
	@include media-breakpoint-down(sm) {
		font-size: $font-size-base * 1.25;
	}
}

h3 {
	@include media-breakpoint-down(sm) {
		font-size: $font-size-base * 1.125;
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 900 !important;
}

// Common reusable placeholders classes
.flex-center {
	display: flex;
	align-items: center;
	justify-content: center;
}

.list-default {
	margin: 0;
	padding: 0;
	list-style: none;
}

.main {
	@include media-breakpoint-down(sm) {
		margin-top: 100px;
	}

	@include media-breakpoint-down(xs) {
		margin-top: 0;
	}
}

&.home {
	.main {
		@include media-breakpoint-down(xs) {
			margin-top: 40px;
		}
	}
}

.pl-sm-0,
.px-sm-0,
.pr-sm-0 {
	padding: 0 15px !important; // !important needed to override base css
}

/* intro */
.intro {
	background: $base-6;
	padding: 8vh 0;
}

.form_success_message {
	font-size: $font-size-base_sm;
	letter-spacing: 1.4px;
}

.btn-back {
	font-family: "Red Hat Display", sans-serif;
	font-size: $font-size-base_sm !important;

	&:hover {
		text-decoration: none;
	}
}
