&.home {
    .main {
        .usps {
            @include media-breakpoint-down(sm) {
                margin-bottom: 50px;
            }
            .container-default {
                .container-holder {
                    ul {
                        @extend .list-default;
                        display: flex;
                        flex-wrap: wrap;
                        margin: 0 0 30px;

                        li {
                            font-size: $font-size-base_sm;
                            color: $black;
                            font-family: $font-red-hat-display;
                            padding-left: 25px;
                            display: flex;
                            align-items: center;
                            position: relative;

                            @include media-breakpoint-up(lg) {
                                padding-left: 20px;
                            }
                            &::before {
                                position: absolute;
                                left: 0;
                                top: 40%;
                                content: "\f058";
                                transform: translateY(-55%);
                                font-size: $font-size-base !important; // !important needed to override base css
                                font-family: $font-awesome;
                                font-weight: 700;
                                color: $highlight;
                                width: 18px;
                                height: 18px;
                            }
                            &:not(:last-child) {
                                margin-bottom: 10px;

                                @include media-breakpoint-up(sm) {
                                    margin-right: 30px;
                                }
                                @include media-breakpoint-up(lg) {
                                    margin-right: 45px;
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
        .intro.content {
            background: $base-6;
            padding: 80px 0 0;
            position: relative;

            &::after {
                background: $white;
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                display: block;
                height: 700px;
            }
            @include media-breakpoint-up(sm) {
                padding: 100px 0 0;

                &::after {
                    height: 300px;
                }
            }
            .container-two-columns {
                margin-bottom: 80px;

                .container-holder {
                    .column.one {
                        @include media-breakpoint-up(lg) {
                            @include make-col(8);
                        }

                        p {
                            max-width: 698px;
                        }
                        .page_block.link {
                            @extend .btn-secondary;
                            margin-bottom: 10px;
                        }
                    }
                    .column.two {
                        @include media-breakpoint-up(lg) {
                            @include make-col(4);
                        }
                    }
                }
            }
            .container-one-column {
                .container-holder {
                    .column.default {
                        .grid.page_block {
                            z-index: 1;
                            position: relative;

                            .item {
                                @include media-breakpoint-up(lg) {
                                    @include make-col(6);
                                }
                                .card {
                                    &-body {
                                        padding: 15px 0 !important; // !important needed to override base css
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .highlight {
            padding: 80px 0;

            @include media-breakpoint-up(sm) {
                padding: 100px 0;
            }

            .container-two-columns {
                .container-holder {
                    .column.one,
                    .column.two {
                        padding: 0 15px !important; // !important needed to override base css
                    }
                    .column.one {
                        @include media-breakpoint-down(sm) {
                            // order: 2;
                            order: 0;
                        }
                        @include media-breakpoint-up(lg) {
                            @include make-col(4);
                        }
                    }
                    .column.two {
                        @include media-breakpoint-down(sm) {
                            order: 1;
                        }
                        @include media-breakpoint-up(lg) {
                            @include make-col(8);
                        }

                        h1 {
                            font-size: $h2-font-size !important; // !important needed to override base css
                            margin-bottom: 20px;
                        }
                        p {
                            margin-bottom: 28px;
                            max-width: 681px;
                            width: 100%;
                        }
                        .page_block.link {
                            @extend .btn-primary;
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }
        .slider.bg-dark.owl-drag {
            .container-holder {
                position: relative;

                &::after {
                    position: absolute;
                    content: "";
                    background: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
                    height: 30%;
                    width: 100%;
                    left: 0;
                    z-index: 11;
                    bottom: 0;
                }
            }
        }
        .facilities {
            padding: 80px 0 0;
            background: rgba($highlight, 0.5);
            margin-top: 16px;

            @include media-breakpoint-up(sm) {
                padding: 100px 0;
            }

            .container-default {
                @include media-breakpoint-down(sm) {
                    padding: 0 30px !important; // !important needed to override base css
                }
                .container-holder {
                    h1 {
                        font-size: $h2-font-size !important; // !important needed to override base css
                    }
                    p {
                        max-width: 700px;
                    }
                    .page_block.link {
                        @extend .btn-secondary;
                        margin-bottom: 20px;

                        @include media-breakpoint-up(sm) {
                            margin-bottom: 85px;
                        }
                    }
                    .grid.page_block {
                        .item {
                            margin-bottom: 0;

                            @include media-breakpoint-up(sm) {
                                margin-bottom: 90px;
                            }
                        }
                    }
                }
            }
        }
        .news {
            background: $white;
            padding: 80px 0 0;
            position: relative;

            &::after {
                background: $secondary;
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                display: block;
                height: 400px;
            }
            @include media-breakpoint-up(sm) {
                padding: 100px 0 0;
            }
            .container-default {
                @include media-breakpoint-down(sm) {
                    padding: 0 30px !important; // !important needed to override base css
                }
                .container-holder {
                    h1 {
                        margin-bottom: 14px;
                    }
                    p {
                        max-width: 774px;
                    }
                    .page_block.link {
                        @extend .btn-primary;
                        margin-bottom: 75px;
                    }
                    .nieuws-section-container-items {
                        z-index: 1;
                        position: relative;

                        @include media-breakpoint-up(lg) {
                            column-count: 3;
                        }
                    }
                }
            }
        }
    }
}
