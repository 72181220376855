.header {
  position: relative;
  z-index: 999;

  transition: $transition-base;

  @include media-breakpoint-up(xl) {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
  }

  /* Header-socket
     ========================================================================== */
  .header-socket {
    background-color: $dark;

    .container-navbar {
      @extend .px-0;

      .column.navbar {
        @extend .navbar-expand;
        @extend .navbar-dark;
        @extend .justify-content-end;

        .socials {
          @extend .flex-wrap;
        }
      }
    }
  }

  /* Header-main
     ========================================================================== */
  .header-main {
    background: $white;

    .container-navbar {
      @extend .px-0;
    }

    .column.navbar {
      @extend .navbar-expand-xl;
    }

    .logo {
      max-width: 75px;
      transition: $transition-base;

      a {
        @extend .d-block;

        img {
          @extend .w-100;
        }
      }
    }

    .navbar-toggler {
      margin: 0 0 0 auto;

      .navbar-toggler-icon {
        width: auto;
        height: auto;

        background-image: none;
      }
    }

    .navbar-collapse {
      .navbar-nav {
        margin: 0 0 0 auto;
      }
    }
  }

  /* Header - Sticky
     ========================================================================== */
  &.sticky {
    @include media-breakpoint-up(xl) {
      position: fixed;
      box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
    }

    .header-main {
      .logo {
        @include media-breakpoint-up(xl) {
          max-width: 40px;
        }
      }
    }
  }

  /* Menu
     ========================================================================== */
  .navbar-collapse {
    &.side_panel {
      &.side_panel_opened {
        .nav-item.active_side_panel {
          display: inherit;

          .dropdown-toggle {
            display: none !important;
          }

          .nav-item {
            display: inherit;
          }
        }
        .nav-item:not(.active_side_panel) {
          display: none;
        }
      }

      .menu-side-panel-header {
        .go-back::after {
          content: "\F060";
          font-family: "Font Awesome 5 Pro";
          font-size: 0.813rem;
          font-weight: 700;
          border: none;
        }
      }
    }
  }
}
