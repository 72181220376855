header {
	&.header {
		@include media-breakpoint-down(lg) {
			margin-top: -20px;
		}

		.header-socket {
			background: $white;

			.container-navbar {
				.container-holder {
					.column.navbar {
						padding: 0 0 0 20px;

						@include media-breakpoint-up(xl) {
							padding: 0 16px !important; // !important needed to override base css
						}

						.page_block.vocabulary_term_list {
							margin: 5px 0;

							@include media-breakpoint-down(lg) {
								width: 66px;
							}

							li {
								margin: 0 30px 0 0;

								a {
									color: $base-1;
									font-size: $font-size-base_sm;

									@include media-breakpoint-down(lg) {
										font-size: 0;
									}
								}

								&:first-child {
									a {
										position: relative;

										&::before {
											position: absolute;
											left: 0;
											content: "\F095";
											font-size: $font-size-base !important; // !important needed to override base css
											font-family: $font-awesome;
											font-weight: normal;
											color: $secondary;

											@include media-breakpoint-up(xl) {
												content: none;
											}
										}
									}
								}

								&:last-child {
									a {
										position: relative;

										&::before {
											position: absolute;
											left: 0;
											content: "\F0E0";
											font-size: $font-size-base !important; // !important needed to override base css
											font-family: $font-awesome;
											font-weight: normal;
											color: $secondary;

											@include media-breakpoint-up(xl) {
												content: none;
											}
										}
									}
								}
							}
						}

						.page_block.site_switcher {
							.navbar-nav.language {
								.nav-item {
									width: 20px;
									height: 20px;
									margin-right: 10px;
									overflow: hidden;
									border-radius: 100%;

									&.active {
										.nav-link {
											opacity: 1;
										}
									}

									.nav-link {
										font-size: $font-size-lg;
										line-height: 1;
										padding: 0;
										opacity: 0.7;

										&:hover {
											opacity: 1;
										}

										img {
											max-width: 100%;
											transform: scale(1.6);
										}
									}
								}
							}
						}
					}
				}
			}
		}

		.header-main {
			background: transparent;

			.container-navbar {
				.container-holder {
					.column.navbar {
						padding: 0 20px;

						@include media-breakpoint-down(lg) {
							flex-direction: column-reverse;
							align-items: flex-start;
							padding: 10px 0;
						}

						@include media-breakpoint-up(xl) {
							padding: 12px 40px 0;
						}

						.page_block.logo {
							max-width: 250px;

							@media screen and (max-width: 375px) {
								// Specific for iphone 5 and smallre devices
								max-width: 150px;
							}

							@include media-breakpoint-down(lg) {
								margin-left: 20px;
								//height: 0;
								//transform: translateY(20px);
							}

							@include media-breakpoint-up(sm) {
								max-width: 300px;
							}
						}

						.navbar-collapse.menu {
							flex-grow: 1;
							@extend .mx-auto;

							@include media-breakpoint-down(lg) {
								width: 100%;
							}

							.navbar-nav {
								@include media-breakpoint-down(lg) {
									margin-bottom: 20px;
								}

								.nav-item {
									.nav-link {
										font-family: $font-red-hat-display;
										color: $highlight;
										font-size: $font-size-base_sm;

										@include media-breakpoint-up(xl) {
											color: white;
											font-size: $font-size-base;
										}

										&:hover {
											opacity: 0.7;
										}
									}

									&:not(:last-child) {
										margin-right: 8px;

										@include media-breakpoint-down(lg) {
											border-bottom: 1px solid $primary;
										}
									}

									.dropdown-menu.show {
										border: none;

										.nav-item {
											margin: 0 8px;

											.nav-link {
												color: $highlight;
												font-size: $font-size-base_sm;

												@include media-breakpoint-up(xl) {
													color: $primary;
												}
											}
										}
									}
								}
							}
						}

						ul.page_block.vocabulary_term_list {
							&:first-of-type {
								margin-top: 30px;
								margin-right: 100px;
								justify-content: flex-end;
								flex-grow: 1;

								@media screen and (max-width: 575px) {
									// Specific for iphone 5 and smallre devices
									right: 125px !important; // !important needed to override base css
								}

								@include media-breakpoint-down(lg) {
									position: absolute;
									top: -27px;
									right: 115px;
									margin: 0;
								}

								@include media-breakpoint-up(sm) {
									padding-right: 11px !important;
								}

								li {
									@include media-breakpoint-down(xl) {
										margin-right: 12px !important; // !important needed to override base css
									}

									&:not(:last-child) {
										margin-right: 16px;
									}

									a {
										font-size: 0;

										i {
											color: $secondary;
											font-size: $font-size-lg;

											@include media-breakpoint-up(xl) {
												color: $white;
											}
										}

										&:hover {
											opacity: 0.7;
										}
									}
								}
							}
						}

						.search-book-link.page_block.vocabulary_term_list {
							position: relative;
							width: 100%;

							@include media-breakpoint-down(lg) {
								right: 20px;
								top: 97px;
								position: absolute;
							}

							li {
								width: 100%;

								a {
									position: absolute;
									right: -40px;
									top: -60px;
									background: $primary;
									border-radius: 18px 0 0 18px !important; // !important needed to override base css
									padding: 5px 15px !important; // !important needed to override base css
									font-size: $font-size-base_xxs !important; // !important needed to override base css
									display: inline-flex;
									align-items: center;
									font-family: $font-red-hat-display;
									color: $white;
									text-decoration: none;
									transition: 0.3s;

									@media screen and (max-width: 375px) {
										// Specific for iphone 5 and smallre devices
										top: -75px;
									}

									@include media-breakpoint-up(sm) {
										font-size: $font-size-base_sm !important; // !important needed to override base css
									}

									@include media-breakpoint-up(xl) {
										top: -50px;
									}

									&:hover {
										opacity: 0.7;
										color: $white;
									}
								}
							}
						}

						.navbar-toggler {
							color: $primary;
							position: absolute;
							top: -32px;
							left: -10px;

							&[aria-expanded="true"] {
								.fa-bars {
									&::before {
										content: "\f00d";
									}
								}
							}

							.navbar-toggler-icon {
								background: none;
							}
						}
					}
				}
			}
		}

		&.sticky {

			@include media-breakpoint-down(lg) {
				position: fixed;
				margin-top: 0;
				top: 0;
				left: 20px;
				right: 20px;
			}

			.header-main {
				background: $secondary;

				.page_block.logo {
					@include media-breakpoint-up(xl) {
						max-width: 250px !important;
					}
				}
			}
		}
	}
}
