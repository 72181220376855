.footer {
  padding: $footer-padding;
  background: $footer-background;

  .footer-top {
    padding: $footer-top-padding;
    background: $footer-top-background;
  }

  .footer-socket {
    padding: $footer-socket-padding;
    background: $footer-socket-background;
  }
}