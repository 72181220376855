.footer {
    &-top {
        background: $secondary;
        padding: 100px 0 !important; // !important needed to override base css

        .container-three-columns {
            .container-holder {
                .column.one {
                    @include media-breakpoint-down(sm) {
                        margin-bottom: 85px;
                    }
                    .page_block.footer_text {
                        p {
                            &:nth-last-of-type(2) {
                                .btn-outline-primary {
                                    position: relative;

                                    &:hover {
                                        &::before {
                                            color: $white;
                                        }
                                    }
                                    &::before {
                                        position: absolute;
                                        left: 20px;
                                        content: "\F095";
                                        font-size: $font-size-base !important; // !important needed to override base css
                                        font-family: $font-awesome;
                                        font-weight: normal;
                                        color: $primary;
                                    }
                                }
                            }
                            &:last-of-type {
                                .btn-secondary {
                                    position: relative;
                                    
                                    &:hover {
                                        &::before {
                                            color: $white;
                                        }
                                    }
                                    &::before {
                                        position: absolute;
                                        left: 20px;
                                        content: "\F0E0";
                                        font-size: $font-size-base !important; // !important needed to override base css
                                        font-family: $font-awesome;
                                        font-weight: normal;
                                        color: $primary;
                                        z-index: 1;
                                    }
                                }
                            }

                            .btn-outline-primary,
                            .btn-secondary {
                                font-family: $font-red-hat-display;
                                font-size: $font-size-base_sm !important; // !important needed to override base css
                                width: 200px;
                                padding: 7px 38px 7px 45px !important; // !important needed to override base css
                                margin-bottom: 8px;
                                display: flex;
                                justify-content: center;
                                position: relative;
                                letter-spacing: 0;
                                border: none !important;
                                @extend .btn-outline-primary;
                            }
                        }
                    }
                }
                .column.two {
                    @include media-breakpoint-down(sm) {
                        margin-bottom: 85px;
                    }
                    .page_block.footer_text {
                        p {
                            a {
                                font-family: $font-red-hat-display;
                                color: $white !important; // !important needed to override base css
                                position: relative;
                                padding-right: 20px;

                                &:hover {
                                    text-decoration: none;

                                    &::after {
                                        right: -10px;
                                    }
                                }

                                &::after {
                                    position: absolute;
                                    top: 50%;
                                    right: 0;
                                    content: "\f0a9";
                                    transform: translateY(-50%);
                                    font-size: $font-size-base !important; // !important needed to override base css
                                    font-family: $font-awesome;
                                    font-weight: normal;
                                    transition: all 300ms;

                                    @include media-breakpoint-up(sm) {
                                        font-size: $font-size-base_sm;
                                        margin-top: 2px;
                                    }
                                }
                            }
                        }
                    }
                }
                .column.three {
                    .page_block.vocabulary_term_list {
                        li {
                            background: $white;
                            border-radius: 100%;

                            a {
                                font-size: 0;
                                color: $base-2;
                                width: 48px;
                                height: 48px;
                                margin: auto;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                border-radius: 100%;

                                &:hover {
                                    text-decoration: none;
                                    background: $base-2;
                                    color: $white;
                                }

                                i {
                                    font-size: $font-size-base * 1.875;
                                }
                            }
                        }
                    }
                }
                .page_block.footer_text {
                    h1 {
                        font-size: $font-size-base * 1.5 !important; // !important needed to override base css
                        font-family: $font-red-hat-display;
                        color: $highlight;
                    }
                    p {
                        &:first-of-type {
                            margin-top: 12px;
                            color: $white;
                        }
                    }
                }
            }
        }
    }
    &-botom {
        padding: 65px 0 0;

        .container-two-columns {
            .container-holder {
                .column.one {
                    @include media-breakpoint-up(md) {
                        @include make-col(3);
                    }
                    .page_block.logo {
                        max-width: 300px;
                    }
                    .page_block.footer_text {
                        margin-top: 48px;

                        @include media-breakpoint-up(xs) {
                            margin-top: 20px;
                            margin-bottom: 50px;
                            p {
                                margin-bottom: 0px !important;
                            }
                        }

                        p {
                            font-family: $font-red-hat-display;
                            margin-bottom: 5px;
                        }
                    }
                }
                .column.two {
                    display: flex;
                    flex-wrap: wrap;

                    @include media-breakpoint-up(md) {
                        @include make-col(9);
                    }

                    .page_block.footer_list {
                        margin-bottom: 50px;

                        @include make-col(6);

                        @include media-breakpoint-up(sm) {
                            @include make-col(4);
                        }
                        @include media-breakpoint-up(lg) {
                            @include make-col(3);
                        }

                        h5 {
                            font-family: $font-red-hat-display;
                            margin-bottom: 8px;
                        }
                        .list {
                            &-item {
                                line-height: 1.3;

                                .link {
                                    color: $secondary;
                                    font-size: $font-size-base_xxs;
                                    letter-spacing: 1.2px;

                                    &:hover {
                                        text-decoration: none;
                                        color: lighten($secondary, 10%);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .page_block.footer_link {
            padding-top: 45px;

            .list {
                justify-content: center;

                &-item {
                    .link {
                        font-size: 0;
                        position: relative;
                        width: 225px;
                        height: 50px;
                        display: block;
                        margin: auto;

                        &::before {
                            content: "";
                            display: block;
                            position: absolute;
                            top: 14px;
                            left: 0;
                            right: 0;
                            width: 100%;
                            height: 40px;
                            background: url("/images/footer-bottom-curve.svg");
                            background-position: top;
                        }
                        &::after {
                            content: "\f0aa";
                            display: block;
                            position: absolute;
                            bottom: -2px;
                            font-size: $font-size-base * 1.5 !important; // !important needed to override base css
                            font-family: $font-awesome;
                            font-weight: normal;
                            left: 50%;
                            transform: translateX(-50%);
                            color: $white;
                        }
                    }
                }
            }
        }
    }
}
