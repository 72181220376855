html {
	font-size: initial;
}

body {
	font-size: $font-size-base;
}

#TommyBookingSupport {
	.tbs-container-fluid {
		@extend .px-0;
	}

	.tbs-navbar-collapse {
		@extend .px-0;
	}

	.tbs-nav {
		li {
			font-size: 14px;
		}
	}

	.tbs-page-overzicht {
		#coupon_code {
			h2 {
				margin-top: 20px;
			}
		}
	}
}

.accommodation_search_book {
	.content {
		display: flow-root;
	}
}


/* custom - op basis van TommyBookingSupport */
#TommyBookingSupport .tbs-navbar-default .tbs-navbar-nav>.tbs-active>a,
#TommyBookingSupport .tbs-navbar-default .tbs-navbar-nav>.tbs-active>a:focus,
#TommyBookingSupport .tbs-navbar-default .tbs-navbar-nav>.tbs-active>a:hover {
	color: #fff;
	background-color: rgba(198, 211, 20, 1);
}

#TommyBookingSupport .tbs-btn-info {
	color: #fff;
	background-color: #c6d314;
	border-color: #c6d314;
}

#TommyBookingSupport .tbs-btn-info:hover {
	background-color: #eb4615;
	border-color: #eb4615
}

#TommyBookingSupport .tbs-btn-success {
	color: #fff;
	background-color: #eb4615;
	border-color: #eb4615;
}

#TommyBookingSupport .tbs-btn-success:hover {
	color: #fff;
	background-color: rgba(198, 211, 20, 1);
	border-color: rgba(198, 211, 20, 1);
}

#TommyBookingSupport .tbs-btn-info,
#TommyBookingSupport .tbs-btn-success {
	transition: all 0.5s ease;
}

body #TommyBookingSupport .tbs-calendar .tbs-day.tbs-selected {
	background-color: rgba(198, 211, 20, 1);
}

#TommyBookingSupport .tbs-row.tbs_persoonscategorien,
#TommyBookingSupport .tbs-row.tbs_accommodaties,
#TommyBookingSupport .tbs-row.tbs_kalenders,
#TommyBookingSupport .tbs-container-fluid.tbs_kassabon,
#TommyBookingSupport .tbs-row.tbs_artikelen,
#TommyBookingSupport .tbs-row.tbs_persoon,
#TommyBookingSupport .tbs-row .tbs-page-overzicht>.tbs-container-fluid>.tbs-row:nth-child(2) {
	background-color: rgb(245, 245, 245) !important;
}

#artikelen {
	h4 {
		font-size: 1rem !important;
	}
}


/* custom - op basis van TommyBookingSupport */
.content .tommy-zeb-wrapper * {
	font-family: 'Red Hat Display', sans-serif !important;
}

.content .tommy-zeb-wrapper .tommy-zeb-left {
	background: rgba(198, 211, 20, 1);
}

.content .tommy-zeb-wrapper .tommy-zeb-left h1 {
	display: none;
}

.content .tommy-zeb-wrapper * h2 {
	color: #fff;
	font-size: 18px;
	font-weight: 700;
}

.content .tommy-zeb-wrapper .tommy-zeb-person-categories h2 {
	margin-top: 0;
}

.content .tommy-zeb-wrapper .tommy-zeb-btn {
	min-width: 150px;
	padding: 8px 20px;
	border-color: #c6d314;
	background-color: #c6d314;
	border-radius: 4px;
	color: #fff;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	text-align: center;
}

.content .tommy-zeb-wrapper .tommy-zeb-btn:hover {
	background-color: rgba(198, 211, 20, 1);
	border-color: rgba(198, 211, 20, 1);
	color: #fff;
}

.content .tommy-zeb-wrapper .tommy-zeb-result-label.ng-binding {
	margin-bottom: 10px;
	color: #000000;
	font-size: 20px;
	font-weight: 700;
	line-height: 1.2;
	letter-spacing: normal;

}

.content .tommy-zeb-wrapper .tommy-zeb-result-price.ng-binding {
	color: #000;
	font-weight: 600;
}

@media (min-width: 1200px) {

	/* custom - op basis van TommyBookingSupport - Zoek en Boek */
	.content .tommy-zeb-wrapper .tommy-zeb-result-image img {
		max-width: 250px;
	}

	.content .tommy-zeb-wrapper .tommy-zeb-result-details {
		width: 520px;
	}
}

@media (min-width: 980px) and (max-width: 1199px) {

	/* custom - op basis van TommyBookingSupport - Zoek en Boek */
	.content .tommy-zeb-wrapper .tommy-zeb-result-image img {
		max-width: 180px;
	}
}


.tbs-clone {
	display: none !important;
}

.tbs-disabled {
	opacity: .4;
	pointer-events: none;
}

.tbs-legenda .tbs-day,
.tbs-calendar .tbs-day {
	overflow: hidden;
	border-radius: 0.5em;
	background-color: #5db862;
	position: relative;
	text-align: center;
	border: 1px solid white;
	transition: background-color 0.25s ease;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.tbs-calendar td {
	border: none;
}

.tbs-calendar .tbs-day div {
	z-index: 50;
	color: rgba(255, 255, 255, 0.4);
	font-size: 1.1em;
	line-height: 200%;
}

.tbs-legenda .tbs-day {
	border-radius: 0.25em;
	border: 0;
}

.tbs-legenda .tbs-day.tbs-nietinmaand,
.tbs-calendar .tbs-day.tbs-nietinmaand {
	visibility: hidden;
}


#TommyBookingSupport .tbs-row.tbs_persoonscategorien,
#TommyBookingSupport .tbs-row.tbs_accommodaties,
#TommyBookingSupport .tbs-row.tbs_kalenders,
#TommyBookingSupport .tbs-container-fluid.tbs_kassabon,
#TommyBookingSupport .tbs-row.tbs_artikelen,
#TommyBookingSupport .tbs-row.tbs_persoon,
#TommyBookingSupport .tbs-row .tbs-page-overzicht>.tbs-container-fluid>.tbs-row:nth-child(2) {
	padding: 20px 0;
	margin: 20px 0;
}

#TommyBookingSupport .tbs_kassabon .tbs-row {
	margin-bottom: 15px;
}

.tbs-btn {
	display: inline-block;
	padding: 6px 12px;
	margin-bottom: 0;
	font-size: 14px;
	font-weight: 400;
	line-height: 1.42857143;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	-ms-touch-action: manipulation;
	touch-action: manipulation;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	background-image: none;
	border: 1px solid transparent;
	border-radius: 4px;
}

#TommyBookingSupport .tbs-container-fluid.tbs_kassabon {
	margin: 33px 0;
	padding: 20px !important;
}

#TommyBookingSupport .tbs-navbar-collapse {
	padding: 0;
}

#TommyBookingSupport .tbs-navbar {
	min-height: auto;
	overflow: hidden;
}



h4.tbs-translate {
	font-size: 1.2rem !important;
}

.tommy-zeb-wrapper .tommy-zeb-result.ng-scope {
	background-color: #EFA18A !important;
}

.content .tommy-zeb-wrapper .tommy-zeb-left {
	background: #efa18a !important;
}

.content .tommy-zeb-wrapper .tommy-zeb-btn,
.content .tommy-zeb-wrapper .tommy-zeb-btn:hover {
	border-color: white !important;
	background-color: white !important;
	color: black !important;
}

.tommy-zeb-wrapper .tommy-zeb-left,
.tommy-zeb-person-category-list {
	background: #272727 !important;
}


body .tommy-zeb-wrapper-mini button.tommy-zeb-btn {
	background-color: #eb4615 !important;
	border-radius: 18px !important;
	margin-top: 35px !important;
}

@media only screen and (max-width: 600px) {

	.tommy-zeb-wrapper.tommy-zeb-wrapper-mini h2,
	.tommy-zeb-wrapper.tommy-zeb-wrapper-mini * {
		display: inline;
		width: 100%;
	}
}
