.card {
    padding: 10px;
    border: 1px solid $base-4;
    box-shadow: 3px 3px 6px rgba($black, 0.16);
    border-radius: 3px;
    margin-bottom: 30px;

    @include media-breakpoint-up(sm) {
        padding: 15px;
        margin-bottom: 0;
    }
    &-body {
        padding: 15px 0 0 !important; // !important needed to override base css

        .card-caption {
            margin-top: 10px;

            .card-title-link {
                margin-bottom: 14px;

                .card-title {
                    font-weight: 700 !important;

                    @include media-breakpoint-up(sm) {
                        font-size: $h3-font-size;
                    }
                }
            }
            .card-description {
                max-height: 100%;

                &::after {
                    content: none;
                }
                &-content {
                    font-size: $font-size-base_sm;
                    letter-spacing: 1.4px;
                }
                .list.list-icons {
                    .list-item {
                        color: $primary;
                        font-family: $font-red-hat-display;
                        font-weight: 700;

                        .icon {
                            i {
                                font-weight: 700;
                            }
                        }
                    }
                }
            }
        }
        .card-buttons {
            .card-btn {
                background: transparent;
                color: $primary;
                font-size: $font-size-base_sm !important;
                border: none;
                padding: 0;
                font-family: $font-red-hat-display;
                font-weight: 900 !important;
                position: relative;
                padding-right: 20px;

                &:hover {
                    text-decoration: none;

                    &::after {
                        right: -10px;
                    }
                }

                &::after {
                    position: absolute;
                    top: 50%;
                    right: 0;
                    content: "\f0a9";
                    transform: translateY(-45%);
                    font-size: $font-size-base_sm !important; // !important needed to override base css
                    font-family: $font-awesome;
                    font-weight: normal;
                    transition: all 300ms;
                }
            }
        }
    }
}

//Facility card
.facilities {
    .card {
        background: transparent;
        box-shadow: none;
        border: none;
        transition: 0.3s;

        &:hover {
            text-decoration: none;
            cursor: pointer;

            .card-image {
                opacity: 0.7;
            }
            .card-caption {
                .card-title {
                    opacity: 0.7;
                }
            }
        }
        &-image,
        &-body {
            background: transparent !important; // !important needed to override base css
        }
        &-image {
            width: 75px;
            height: 75px;
            margin: auto;
            font-size: $font-size-base * 1.125 !important; // !important needed to override base css

            img {
                width: 75px;
                height: 75px;
            }
        }
        &-body {
            padding: 0;

            .card-caption {
                margin: 25px auto 0;

                .card-title {
                    color: $secondary;
                    font-size: $font-size-base * 1.125;
                    text-align: center;
                    position: relative;
                    padding-right: 40px;
                    font-weight: 700 !important;

                    &::after {
                        position: absolute;
                        top: 50%;
                        right: 10px;
                        height: 18px;
                        width: 18px;
                        content: "\F0A9";
                        transform: translateY(-50%);
                        font-size: $font-size-base * 1.0625 !important; // !important needed to override base css
                        font-family: $font-awesome;
                        font-weight: normal;
                    }
                }
            }
        }
    }
}
