.eyecatcher {
    position: relative;

    &.large {
        min-height: $carousel-min_height;

        @include media-breakpoint-up(lg) {
            min-height: $carousel-min_height_lg;
        }
        .eyecatcher-container {
            .container-holder {
                .owl-carousel {
                    min-height: $carousel-min_height !important; // !important needed to override base css

                    @media only screen and (max-width: 900px) and (orientation: landscape) {
                        // Specific for landscape device
                        min-height: 350px !important; // !important needed to override base css
                    }
                    @include media-breakpoint-up(lg) {
                        min-height: $carousel-min_height_lg !important; // !important needed to override base css
                    }
                    .owl-stage-outer {
                        .owl-stage {
                            .owl-item {
                                .item {
                                    min-height: $carousel-min_height !important; // !important needed to override base css

                                    @media only screen and (max-width: 900px) and (orientation: landscape) {
                                        // Specific for landscape device
                                        min-height: 350px !important; // !important needed to override base css
                                    }
                                    @include media-breakpoint-up(lg) {
                                        min-height: $carousel-min_height_lg !important; // !important needed to override base css
                                    }

                                    .owl-container {
                                        .owl-caption {
                                            .owl-title {
                                                @media only screen and (max-width: 900px) and (orientation: landscape) {
                                                    // Specific for landscape device
                                                    font-size: $h1-font-size;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .page_block.vocabulary_term_list {
                    bottom: -50px;
                    width: 90%;

                    @include media-breakpoint-up(sm) {
                        bottom: 80px;
                    }
                }
            }
        }
    }
    .eyecatcher-container {
        .container-holder {
            position: relative;

            &::before {
                position: absolute;
                content: "";
                background: linear-gradient(180deg, rgba($black, 0.5), rgba(0, 0, 0, 0));
                height: 30%;
                width: 100%;
                left: 0;
                z-index: 11;
                top: 0;
            }
            .page_block.vocabulary_term_list {
                max-width: 540px;
                position: absolute;
                background: $secondary;
                left: 0;
                right: 0;
                bottom: -50px;
                z-index: 11;
                border-radius: 3px;
                width: 100%;
                padding: 31px 20px;
                margin: auto;
                box-shadow: 3px 3px 6px rgba($black, 0.16);
                color: $white;
                width: 90%;

                @include media-breakpoint-up(sm) {
                    bottom: 20px;
                    width: 100%;
                }

                li {
                    display: flex;
                    margin: auto;

                    a {
                        @extend .btn-primary;

                        @include media-breakpoint-down(sm) {
                            margin-top: 20px;
                        }
                    }
                }
            }
            .go-down-arrow {
                display: flex;
                justify-content: center;
                position: relative;
                font-size: 0;
                width: 100%;
                height: 32px;
                z-index: 12;
                text-align: center;

                @include media-breakpoint-down(sm) {
                    // Specific forsmaller devices
                    display: none;
                }
                &:hover {
                    &::before {
                        bottom: 10px;
                    }
                }

                &::before {
                    content: "\f0ab";
                    display: block;
                    position: absolute;
                    bottom: 16px;
                    font-size: $font-size-base * 1.5 !important; // !important needed to override base css
                    font-family: $font-awesome;
                    font-weight: normal;
                    left: 50%;
                    transform: translateX(-50%);
                    color: $primary;
                    z-index: 11;
                    transition: all 300ms;
                }
                &::after {
                    content: url("/images/banner-bottom-curve.svg");
                    display: block;
                    position: absolute;
                    bottom: 24px;
                    width: 100%;
                    height: 32px;
                    z-index: 1;
                    text-align: center;
                }
            }
        }
    }
}
