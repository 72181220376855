.btn {
    display: inline-flex;
    align-items: center;
    padding: 5px 50px;
    font-size: $font-size-base_sm !important;
    font-family: $font-red-hat-display;
    border-radius: 18px;
    color: $white;
    text-decoration: none;
    transition: 0.3s;

    @include media-breakpoint-up(lg) {
        font-size: $font-size-base !important;
    }

    &-primary {
        @extend .btn;
        color: $white;
        background: $primary;

        &:hover {
            color: $primary;
            background: $white;
            border-color: $primary
        }
    }
    &-secondary {
        @extend .btn;
        color: $white;
        background: $secondary;

        &:hover {
            color: $secondary;
            background: $white;
            border: 1px solid $secondary;
        }
    }
    &-outline-primary{
        color: $primary !important;
        background: $white !important;

        &:hover {
            color: $white !important;
            background: $primary !important;
        }
    }
    &-outline-secondary {
        color: $secondary !important;
        background: $white !important;

        &:hover {
            color: $white !important; 
            background: $secondary !important;
        }
    }
}
