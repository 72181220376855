&.service_overview, &.service_category, &.service_detail {
    .main {
        .intro {
            background: $base-6;
            padding: 80px 0 140px;
            margin-top: 16px;
            position: relative;

            @include media-breakpoint-up(sm) {
                padding: 100px 0 200px;
            }

            .container-default {
                .container-holder {
                    p {
                        max-width: 698px;
                    }
                }
            }
        }
        .content {
            padding: 0 0 80px;
            margin-top: -100px;

            @include media-breakpoint-up(sm) {
                margin-top: -170px;
            }

            .container-default {
                .container-holder {
                    .grid.page_block.service_overview {
                        .item {
                            @include media-breakpoint-up(lg) {
                                @include make-col(4);
                            }
                        }
                    }
                }
                &:last-of-type {
                    margin-top: 60px;

                    p {
                        max-width: 774px;
                    }
                    .page_block.link {
                        @extend .btn-primary;
                    }
                }
            }
        }
    }
}
